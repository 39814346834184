/* eslint-disable import/prefer-default-export */
// Icons
import iconHanger from '@/assets/icons/hanger.svg';
import iconTintoreria from '@/assets/icons/tintoreria.png';
import iconSastre from '@/assets/icons/sastre.png';
import iconPlanchado from '@/assets/icons/planchado.png';

// eslint-disable-next-line arrow-body-style
export const getServiceIcon = (icon) => {
  return {
    lavanderia: iconHanger,
    tintoreria: iconTintoreria,
    sastre: iconSastre,
    planchado: iconPlanchado,
  }[icon];
};
