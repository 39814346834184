<template>
  <Form @submit="save">
    <v-row>
      <v-col>
        <v-breadcrumbs :items="breadcrumbs" class="ma-0 pa-0" />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <h1>{{ this.uuid ? 'Editar' : 'Agregar' }} oficina</h1>
      </v-col>

      <v-col cols="12" sm="6" lg="4">
        <TextField
          v-model.trim="form.name"
          label="Nombre *"
          prepend-icon="mdi-store"
          rules="required"
          ref="name"
          @blur="verifyName"
        />
      </v-col>

      <v-col cols="12" sm="6" lg="4">
        <TextField
          v-model="form.phone"
          counter="12"
          label="Teléfono *"
          maxlength="12"
          prepend-icon="mdi-phone"
          rules="required|min:8|max:12"
        />
      </v-col>

      <v-col cols="12">
        <ComboField
          v-model="form.postal_codes"
          label="Códigos Postales *"
          prepend-icon="mdi-google-maps"
          rules="required"
          chips
          clearable
          deletable-chips
          dense
          multiple
        />
      </v-col>

      <v-col cols="12">
        <v-row>
          <v-col cols="12" md="4">
            <FileField
              accept="image/*"
              placeholder="Subir logo"
              prepend-icon="mdi-camera"
              label="Logo"
              @change="previewLogo"
            />
          </v-col>

          <v-col cols="12" md="4">
            <v-img v-if="logoUrl" :src="logoUrl" width="150" height="150" />
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12">
        <h3>Servicios</h3>

        <div class="d-flex flex-wrap justify-space-between">
          <template v-for="service in services">
            <v-checkbox v-model="form.services" :value="service.id" :key="service.id">
              <template v-slot:label>
                <img :src="getServiceIcon(service.icon)" class="service-icon" />
                {{ service.name }}
              </template>
            </v-checkbox>
          </template>
        </div>
      </v-col>
    </v-row>

    <v-divider class="my-5" />

    <v-row>
      <v-col cols="12" class="d-flex">
        <h3>Responsables</h3>

        <v-btn class="ml-3" color="primary" small @click="addAdmin">
          <v-icon>mdi-plus</v-icon>
          Agregar
        </v-btn>
      </v-col>
    </v-row>

    <AdminForm
      v-for="(admin, index) in form.admins"
      :key="admin.uuid || index"
      :admin="admin"
      :index="index"
      :show-remove="showRemove"
      @remove="handleRemoveAdmin"
    />

    <v-divider class="my-5" />

    <AddressForm :address="form.address" />

    <v-row>
      <v-col cols="12" class="text-center">
        <v-btn color="primary" type="submit">
          <v-icon left>mdi-content-save</v-icon>
          Guardar
        </v-btn>
      </v-col>
    </v-row>

    <Modal :show.sync="showDeleteModal" @submit="removeAdmin">
      <template #title>Eliminar</template>

      <template #body>
        ¿Deseas eliminar al responsable
        <b>{{ selectedAdmin.first_name | uppercase }}</b> ?
      </template>

      <template #action>Eliminar</template>
    </Modal>
  </Form>
</template>

<script>
// API
import OfficeAPI from '@/api/office';
import ProductAPI from '@/api/product';

// Utils
import { getServiceIcon } from '@/utils/service';

// Components
import AddressForm from '@/components/address/Form.vue';
import Modal from '@/components/modal/index.vue';
import AdminForm from './components/AdminForm.vue';

export default {
  name: 'OfficeForm',

  props: {
    uuid: {
      type: String,
      default: null,
    },
  },

  components: {
    AddressForm,
    AdminForm,
    Modal,
  },

  data: () => ({
    form: {
      address: {
        street: null,
        outer_number: null,
        inner_number: null,
        suburb: null,
        municipality: null,
        city: null,
        state: null,
        country: null,
        postal_code: null,
      },
      admins: [],
      name: null,
      phone: null,
      postal_codes: [],
      services: [],
    },
    logo: null,
    logoBlobUrl: null,
    showDeleteModal: false,
    selectedAdmin: {},
    services: [],
  }),

  async created() {
    this.api = new OfficeAPI();
    this.productAPI = new ProductAPI();
    await this.getServices();

    if (this.uuid) {
      await this.detail();
    } else {
      this.addAdmin();
    }
  },

  computed: {
    breadcrumbs() {
      const items = [
        {
          text: 'Sucursales',
          disabled: false,
          exact: true,
          to: { name: 'office-list' },
        },
      ];

      if (this.uuid) {
        const name = this.form.name ? this.form.name : '';
        items.push(
          {
            text: name.toUpperCase(),
            disabled: false,
            exact: true,
            to: { name: 'office-detail', params: { uuid: this.uuid } },
          },
          {
            text: 'Editar',
            disabled: true,
          },
        );
      } else {
        items.push({
          text: 'Agregar',
          disabled: true,
        });
      }

      return items;
    },

    logoUrl() {
      return this.logoBlobUrl || this.form.logo;
    },

    showRemove() {
      return this.form.admins.length > 1;
    },
  },

  methods: {
    getServiceIcon,

    async save() {
      this.$store.commit('showLoader');
      try {
        let id;

        if (this.uuid) {
          id = this.uuid;
          await this.api.update(this.uuid, this.form);
        } else {
          const office = await this.api.create(this.form);
          id = office.uuid;
        }

        // Upload image and free memory
        if (this.logo) {
          const formData = new FormData();
          formData.append('logo', this.logo);
          await this.api.logo(id, formData);
          URL.revokeObjectURL(this.logoUrl);
        }

        this.$router.push({ name: 'office-detail', params: { uuid: id } });
        this.$store.dispatch('notification/notifySuccess');
      } catch (error) {
        this.$store.dispatch('notification/notifyError', 'No se pudieron guardar los datos.');
      }
      this.$store.commit('hideLoader');
    },

    async detail() {
      this.$store.commit('showLoader');
      try {
        this.form = await this.api.detail(this.uuid);
        this.form.services = this.form.services.map((service) => service.id);
      } catch (error) {
        this.$store.dispatch('notification/notifyError');
      }
      this.$store.commit('hideLoader');
    },

    async getServices() {
      this.$store.commit('showLoader');
      try {
        const res = await this.productAPI.services();
        this.services = res.results;
      } catch (error) {
        this.$store.dispatch('notification/notifyError');
      }
      this.$store.commit('hideLoader');
    },

    async verifyName() {
      if (!this.form.name) return;

      this.$store.commit('showLoader');
      try {
        const data = {
          name: this.form.name,
        };

        if (this.uuid) {
          data.uuid = this.uuid;
        }

        const { available } = await this.api.verifyName(data);

        if (!available) {
          this.$refs.name.setErrors([
            'Ya existe una oficina con este nombre.',
          ]);
        }
      } catch (error) {
        this.$store.dispatch('notification/notifyError');
      }
      this.$store.commit('hideLoader');
    },

    addAdmin() {
      this.form.admins.push({
        email: null,
        first_name: null,
        last_name: null,
        mother_last_name: null,
        password: null,
        phone: null,
      });
    },

    removeAdmin() {
      this.form.admins = this.form.admins.filter((a) => a !== this.selectedAdmin);
      this.showDeleteModal = false;
    },

    handleRemoveAdmin(admin) {
      this.selectedAdmin = admin;

      if (!admin.first_name) {
        this.removeAdmin();
        return;
      }

      this.showDeleteModal = true;
    },

    previewLogo(file) {
      if (file) {
        this.logoBlobUrl = URL.createObjectURL(file);
        this.logo = file;
      } else {
        URL.revokeObjectURL(this.logoBlobUrl);
        this.logoBlobUrl = null;
        this.logo = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
