<template>
  <v-row>
    <v-col cols="12">
      <b>Responsable {{ index + 1 }}</b>

      <v-btn
        v-if="showRemove"
        color="error"
        icon
        @click="$emit('remove', admin)"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </v-col>

    <v-col cols="12" md="6" lg="4">
      <TextField
        v-model="admin.first_name"
        label="Nombre *"
        prepend-icon="mdi-account"
        rules="required"
        :disabled="disabled"
      />
    </v-col>

    <v-col cols="12" md="6" lg="4">
      <TextField
        v-model="admin.last_name"
        label="Apellido Paterno *"
        prepend-icon="mdi-account"
        rules="required"
        :disabled="disabled"
      />
    </v-col>

    <v-col cols="12" md="6" lg="4">
      <TextField
        v-model="admin.mother_last_name"
        label="Apellido Materno *"
        prepend-icon="mdi-account"
        rules="required"
        :disabled="disabled"
      />
    </v-col>

    <v-col cols="12" md="6" lg="4">
      <TextField
        v-model="admin.email"
        label="Correo electrónico *"
        prepend-icon="mdi-at"
        rules="required|email"
        :disabled="disabled"
      />
    </v-col>

    <!-- <v-col cols="12" md="6" lg="4">
      <TextField
        v-model="admin.password"
        label="Contraseña *"
        prepend-icon="mdi-key"
        rules="required"
        :disabled="disabled"
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        :type="showPassword ? 'text' : 'password'"
        @click:append="showPassword = !showPassword"
      />
    </v-col> -->
  </v-row>
</template>

<script>
export default {
  name: 'AdminForm',

  props: {
    admin: {
      type: Object,
      required: true,
    },

    showRemove: {
      type: Boolean,
      default: false,
    },

    index: {
      type: Number,
      required: true,
    },
  },

  computed: {
    disabled() {
      return !!this.admin.uuid;
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
