// API
import BaseAPI from './base';

/**
 * Implement Product API
 */
export default class ProductAPI extends BaseAPI {
  constructor() {
    super('products');
  }

  /**
   * Get available services.
   */
  services(params = {}) {
    return this.request.get('v1/services', { params });
  }
}
